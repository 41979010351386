import React from 'react';
import { Container } from 'react-bootstrap';

import Framework from '../../components/framework';
import NavTop from '../../components/framework/nav-top';
import Seo from '../../components/framework/seo';
import LegalBreadcrumb from '../../components/legal/breadcrumb';
import Trans from '../../components/translation/trans';
import useTranslation from '../../hooks/use_translation';

const LegalTerms = () => {
	const { t } = useTranslation('legal_terms');

	return (
		<Framework>
			<Seo title={t('title')} />
			<NavTop />

			<Container className="mt-5 pt-5">
				<LegalBreadcrumb title={t('t1')} />
				<h1>{t('t1')}</h1>
				<Trans i18nKey="t2" t={t} />
			</Container>
		</Framework>
	);
};

export default LegalTerms;
